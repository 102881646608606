import LocalizedStrings, { LocalizedStringsMethods } from "react-localization"

export interface ILabels extends LocalizedStringsMethods {
  Welcome: string
  NotLiveNotice: string

  ShipmentType: string
  ShipmentTypePickup: string
  ShipmentTypeDelivery: string

  HowManyKits: string
  HowManyKitsV3: string
  HowManyKitsValidation: string
  UserID: string
  CovidKit: string
  CovidKitOrdered: string
  PickupInventoryWarning: string

  Location: string
  PickupLocation: string
  LocationValidation: string

  NameTitle: string
  FirstName: string
  FirstNameValidation: string
  LastName: string
  LastNameValidation: string

  DoubleCheckWarningSubmit: string
  DoubleCheckWarningConfirm: string
  DeliveryNote: string
  DeliveryNote2: string
  DeliveryNotAvailable: string
  PhoneNumberTitle: string
  PhoneNumberDescription: string
  PhoneNumberValidation: string
  AddressTitle: string
  AddressSubtitle: string
  StreetName: string
  StreetNamePlaceholder: string
  StreetNameValidation: string
  BuildingNumber: string
  BuildingNumberValidation: string
  Unit: string
  City: string
  CityPlaceholder: string
  CityValidation: string
  PostalCode: string
  PostalCodeValidation: string
  DeliveryAddressTitle: string

  Confirm: string
  Submit: string
  Saving: string

  FormSubmitted: string
  FormSubmittedPickup: string
  FormSubmittedDelivery: string
  FormV3Submitted: string
  CantSubmitErrorInternal: string
  CantSubmitErrorReceivedAll: string
  CantSubmitErrorReceivedSome: string

  KitDelivered: string
  KitNotDelivered: string
  KitMarkedDelivered: string
  KitMarkedNotDelivered: string

  Alberta: string
  BritishColumbia: string
  Manitoba: string
  NewBrunswick: string
  NewfoundlandAndLabrador: string
  NovaScotia: string
  Ontario: string
  PrinceEdwardIsland: string
  Quebec: string
  Saskatchewan: string
  NorthwestTerritories: string
  Nunavut: string
  Yukon: string

  Yes: string
  No: string

  UserNotValid: string,
  UserIsValid: string,

  ErrorUnexpected: string,
}

export const AppLabels: ILabels = new LocalizedStrings({
  en: {
    Welcome: "Welcome to the HIV self-test kit order form for the <a href='https://readytoknow.ca/ready-get-a-kit/im-ready-research-program/'>I'm Ready research program.</a> You can order a total of 3 free kits from this program. You don't have to order them all at once. You can either order your kits for delivery to your home or another address, or you can pick them up at one of our <a href='https://readytoknow.ca/ready-get-a-kit/pickup-locations/'>pickup locations.</a> Please note that you can't pick up kits at a pickup location without ordering them first through this form.",
    NotLiveNotice: "<strong>NOTICE:</strong> We're getting ready to launch the I'm Ready research program but we’re not live yet and neither is this order form. Follow us on Twitter, Facebook and Instagram at @ImReadyJagis for updates.",

    ShipmentType: "Do you want to order your kit(s) for pick up or for delivery?",
    ShipmentTypePickup: "Pick up my kit(s)",
    ShipmentTypeDelivery: "Have my kit(s) delivered to my home or another address <span class=\"fs85\">- Only available Monday – Saturday between the hours of 10:00am and 8:00pm</span>",

    HowManyKits: "How many self-test kits do you want to order now? You can order up to 3 free kits from this program. You don’t have to order them all now.",
    HowManyKitsV3: "How many HIV self-test kits do you want? You can order a total of 5 free kits from this research program",
    HowManyKitsValidation: "The number of self-test kits must be between 1 and 3.",
    UserID: "Unique user ID (from App):",
    CovidKit: "Would you like one package of COVID antigen test kits?",
    CovidKitOrdered: "You have already ordered the one COVID antigen test avaliable to you through the I'm Ready research program.",
    PickupInventoryWarning: "We manage the number of kits at each location very carefully. There should always be kits available for pick-up. However, you can contact the location if you want to be very sure. To view the contact information of our pick-up location partners tap <a href='https://www.readytoknow.org.jm/ready-get-a-kit/pickup-locations/'>https://www.readytoknow.org.jm/ready-get-a-kit/pickup-locations/</a>.",

    Location: "Which of the I'm Ready pickup locations do you intend to pickup your test kits from:",
    PickupLocation: "Pickup Location",
    LocationValidation: "Location is required.",

    NameTitle: "Please enter a first and last name. These can be your legal names or you can make them up.",
    FirstName: "First name",
    FirstNameValidation: "First name is required. This can be your legal name or you can make one up.",
    LastName: "Last name",
    LastNameValidation: "Last name is required. This can be your legal name or you can make one up.",

    DoubleCheckWarningSubmit: "<strong>Please double check that you have entered your correct mailing address before pressing \"submit\" on this form.</strong> Please note that the address entered below is where the kit(s) will be delivered to. By submitting this form for delivery you are agreeing that your information provided in this form can be shared with the third party delivery program. Please use the Autogenerated Name when picking up the kit(s) at the delivery location. Note that once the address location has been submitted, no changes can be made for deliver location and delivery is the responsibility of the carrier.",
    DoubleCheckWarningConfirm: "<strong>Please double check that you have entered your correct mailing address before pressing \"confirm\" on this form.</strong> Please note that the address entered below is where the kit(s) will be delivered to. By submitting this form for delivery you are agreeing that your information provided in this form can be shared with the third party delivery program. Please use the Autogenerated Name when picking up the kit(s) at the delivery location. Note that once the address location has been submitted, no changes can be made for deliver location and delivery is the responsibility of the carrier.",
    
    DeliveryNote: "To protect your privacy, we have autogenerated a <strong>Random Delivery Name</strong> that you will use when collecting your package from the delivery driver. This information will appear in the Delivery Order Summary on the next screen.",
    DeliveryNote2: "Please also note that once the address location has been submitted, no changes can be made to the delivery location. You will receive your package within 120 minutes of submitting your order.",
    DeliveryNotAvailable: "Delivery services are only available Monday – Saturday between the hours of 10:00am and 8:00pm. Please select the Kit for pick-up option or come back during available hours to request a kit for delivery.",
    PhoneNumberTitle: "Phone number",
    PhoneNumberDescription: "A phone number is required to notify you when your package is being delivered to your selected address. The delivery driver will call you to confirm the location and update you on the delivery time. Please ensure this phone number is correct, no changes can be made once you tap <strong>Submit</strong> and then tap <strong>Confirm</strong>.",
    PhoneNumberValidation: "Phone number is required with the format +1 123-456-7890.",
    AddressTitle: "Please enter the address that you want your kit(s) delivered to. This can be your home or another address.",
    AddressSubtitle: "Note: We use your address information only for the delivery. It is not stored and won’t be linked to the data we collect.",
    BuildingNumber: "Building number",
    BuildingNumberValidation: "Building number or PO box address is required.",
    StreetName: "Street name",
    StreetNamePlaceholder: "Street",
    StreetNameValidation: "Street name, or station number if you're using a PO box, is required.",
    Unit: "Unit",
    City: "City",
    CityPlaceholder: "City",
    CityValidation: "City is required.",
    PostalCode: "Postal code",
    PostalCodeValidation: "Postal code is required.",
    DeliveryAddressTitle: "Delivery Address",

    Confirm: "Confirm",
    Submit: "Submit",
    Saving: "Saving",

    FormSubmitted: "Thank you! You will receive your kit(s) within two weeks if you ordered them for delivery. If you chose to pick up your kit(s), visit any of our <a href='https://imready.jamaica.dev.sandboxsoftware.ca/ready-get-a-kit/pickup-locations/'>pickup locations</a> to claim your kits using the self-test kit pickup codes you can find in the menu screen of the I'm Ready, Test app. This is at the bottom-right of your screen when you have the app open.",
    FormSubmittedPickup: "Thank you for requesting a Test Kit Package for pickup. Visit any of our pickup locations to get your kits using the pickup codes you can find by pressing the menu button in the app.", 
    FormSubmittedDelivery: "Thank you for ordering your self-test kits! You should receive your self-test kit package within 120 minutes! <br /><br />You will get a call from the delivery driver to confirm your location and to update you on the delivery time. To maintain your privacy, use the information below including the autogenerated <strong>Random Delivery Name</strong> when you are collecting the self-test kit package from the delivery driver.",
    FormV3Submitted: "Thank you! Your kits are on the way!",
    CantSubmitErrorInternal: "Error submitting form, internal server error",
    CantSubmitErrorReceivedAll: "Thanks for being part of the I'm Ready research program. Unfortunately, we have a limited number of free HIV self-testing kits and you have already ordered your maximum of 3. Is there anything we can do to support you or connect you to other resources for testing, care and prevention? We suggest booking an appointment with one of our peer navigators through our <a href='https://connect.im-ready.ca/booking/steps/matching'>I'm Ready, Talk secure telehealth service.</a>",
    CantSubmitErrorReceivedSome: "You can order a total of 3 kits as an I’m Ready research program participant. You have already ordered {1}, so you can order {2} more.",

    KitDelivered: "Kit Delivered",
    KitNotDelivered: "Kit Not Delivered",
    KitMarkedDelivered: "Congratulations! You have successfully received your self-test kit package! Tap <strong>Proceed</strong> {1} at the top of the screen to continue and get instructions on how to use the self-test kit correctly.",
    KitMarkedNotDelivered: "We are sorry that you have not received your self-test kit package. Please tap <strong>Back</strong> {1} at the top of the screen to request a new package in the app or you can talk to a Peer Navigator by using the <strong>Connect</strong> button {2} who can help you to get a kit. You can also decide to do a lab test instead using the <a href=\"https://www.healthconnectja.com\">www.healthconnectja.com</a> website to book a free test at one of our private labs of your choice.",

    Alberta: "Alberta",
    BritishColumbia: "British Columbia",
    Manitoba: "Manitoba",
    NewBrunswick: "New Brunswick",
    NewfoundlandAndLabrador: "Newfoundland and Labrador",
    NovaScotia: "Nova Scotia",
    Ontario: "Ontario",
    PrinceEdwardIsland: "Prince Edward Island",
    Quebec: "Quebec",
    Saskatchewan: "Saskatchewan",
    NorthwestTerritories: "Northwest Territories",
    Nunavut: "Nunavut",
    Yukon: "Yukon",

    Yes: "Yes",
    No: "No",
    UserNotValid:"<strong>Attention! We have important updates for you</strong>. To ensure the best experience with our app, please <strong>update to the latest version</strong>. Once you've updated, you'll be prompted to switch to our new program for even more opportunities to get resources, free HIV self-test kits and linkage to care information. Please note that this will require an additional update. If you choose to switch to the new program, your data stored on the app will be deleted. To get started, simply click on the link for <a href='https://apps.apple.com/us/app/im-ready-test/id1547497367/'>iOS</a> or <a href='https://play.google.com/store/apps/details?id=com.ImReadyJamaicatoronto.imreadytest&hl=en&gl=US&pli=1/'>Android<a/> and follow the instructions.",
    UserIsValid: "Welcome to the HIV self-test kit order form for the I'm Ready program. You can order a total of 3 free HIV kits from this program. You don't have to order them all at once. You can either order your kits for delivery to your home or another address, or you can pick them up at one of our pickup locations. Please note that you can't pick up kits at a pickup location without ordering the first through this form.",

    ErrorUnexpected: "We are sorry, there was an unexpected error"
  }
});